import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCover from "../../assets/images/hope-probe/launch-vehicle/cover.png"
import imgRocket from "../../assets/images/hope-probe/launch-vehicle/rocket.png"

const LaunchVehiclePage = () => (
  <Layout>
    <SEO title="Launch Vehicle" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div className="mb5">
                  <h2 className="relative">
                  Launch Vehicle
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  The Hope Probe will be launched into space from the Tanegashima Space Centre in Japan aboard an H2A202 rocket, which is part of the H-IIA launch vehicle family. The H-IIA Launch Vehicle is a high-performance rocket developed and operated by Mitsubishi Heavy Industries. Mitsubishi Heavy Industries provides all the services related to the spacecraft launch including launch vehicle manufacturing, interface coordination, and launch operations at the Tanegashima Space Centre. The Japan Aerospace Exploration Agency (JAXA) takes care of flight safety, range safety and launch site facility.                      
                  </p>
                  <p>
                  The launch vehicle consists of a first stage, second stage, fairing, and a pair of Solid Rocket Boosters (SRB-As). The propulsion system of the launch vehicle uses liquid hydrogen and liquid oxygen.
                  </p>
                </div>
                <div className="mv5">
                    <h3>
                    First Stage
                    </h3>
                    <p>
                    The first stage of the H-IIA rocket system is made up of a high-performance LE-7A engine, an engine section, a propulsion system tank charged with liquid hydrogen and liquid oxygen, a centre section connecting the tanks, and an inter-stage section connecting the first and second stages. 
                    </p>
                </div>
                <div className="mv5">
                    <h3>
                    Solid Rocket Booster (SRB-A) 
                    </h3>
                    <p>
                    The Solid Rocket Boosters (SRB-A) supplement the total thrust by firing for approximately 100 seconds from liftoff. The two Solid Rocket Boosters are attached to the first stage. 
                    </p>
                </div>
                <div className="mv5">
                    <h3>
                    Second Stage
                    </h3>
                    <p>
                    The second stage comprises a LE-5B engine, a propulsion system tank that is charged with liquid hydrogen and liquid oxygen and on-board electronic devices. The LE-5B engine in the second stage can be fired up to three times.
                    </p>
                </div>
                <div className="flex flex-column flex-row-ns mb5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgRocket} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pl5-ns z-index-10">
                        <div>
                            <p className="mv4">
                            The second stage comprises a LE-5B engine, a propulsion system tank that is charged with liquid hydrogen and liquid oxygen and on-board electronic devices. The LE-5B engine in the second stage can be fired up to three times.
                            </p>
                        </div>
                        <div className="negative-margin-left-150 w-80-ns">
                        <table className="uk-table uk-table-responsive uk-table-small home-custom-table">
                            <tbody className="text-white">
                                <tr>
                                    <td className="text-highlight-brown">
                                    TYPE
                                    </td>
                                    <td>
                                    H2A202
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    HEIGHT
                                    </td>
                                    <td>
                                    53 meters
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    GROSS MASS
                                    </td>
                                    <td>
                                    289 metric tons (excluding satellite mass)
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    FIRST STAGE ENGINE
                                    </td>
                                    <td>
                                    LE-7A×1
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    SECOND STAGE ENGINE
                                    </td>
                                    <td>
                                    LE-5B×1
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    SOLID ROCKET BOOSTER
                                    </td>
                                    <td>
                                    2
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    LAUNCH CAPACITY
                                    </td>
                                    <td>
                                    4.0 metric tons
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default LaunchVehiclePage
